<template>
  <div id="app" class="">
    <div class="app-wrap">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
  /*#app {
    padding: 0.5em;
    position: relative;
    min-height: 100vh;
  }

  .app-wrap {
    padding-bottom: 11rem;
    margin-bottom: 3em;
  }*/
</style>
