<template>
  <nav class="jd-nav">
    <div class="level is-mobile is-marginless">
      <div class="level-left">
        <div class="level-item is-vertical">
          <router-link to="/">
            <img :src="logoImageLink" alt="JDoodle" width="100" height="20" class="logo">
          </router-link>
        </div>
      </div>
    </div>
    <component v-bind:is="currentSideBarComponent" @toggle="currentSideBarComponent=null" v-bind="propForSideBar"
    @changeComponent="changeComponent" @changePage="changePage"></component>
  </nav>
</template>

<script>
import { eventBus } from '../assets/javascript/event-bus'

export default {
  name: 'Header',
  components: {
    SideBar: () => import('./Utils/SideBar')
  },
  data: () => {
    return {
      logoImage: 'jdoodle',
      currentSideBarComponent: null,
      propForSideBar: null
    }
  },
  mounted () {
    eventBus.$on('showInSideBar', (component, properties) => {
      this.currentSideBarComponent = component
      this.propForSideBar = properties
    })
  },
  methods: {
    changeComponent (newComponent) {
      this.currentSideBarComponent = newComponent
    },
    changePage (newPage) {
      this.$router.push(newPage)
      this.currentSideBarComponent = null
    }
  },
  computed: {
    logoImageLink () {
      return require('../assets/images/' + this.logoImage + '.png')
    }
  }
}
</script>

<style scoped lang="scss">
  .jd-nav {
    padding-top: 1em;
    margin-bottom: 1em;
  }

  .logo {
    margin-top: -16px;
  }

  .share-button {
    background-color: #fa745e;
  }

  .dropdown-menu {
    margin-top: -5px;
  }
</style>
