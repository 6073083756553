<template>
  <footer class="footer">
    <div class="contact-us">contact us at <a href="mailto:jdoodle@nutpan.com" id="contact-us">jdoodle@nutpan.com</a></div>
    <div class="copyright">© 2013-{{currentYear}} Nutpan pty Ltd. All Rights Reserved.</div>
    <div class="twitterfollow">
      <a href="https://twitter.com/thenutpan" rel="nofollow" class="twitter-follow-button" data-show-count="false" data-show-screen-name="false">Follow @thenutpan</a>
    </div>
    <div><span class="text-info">JDoodle uses cookies</span>. Please read full <a href="/terms">Terms of Use</a> before using this service.</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
  .footer {
    text-align: center;
    margin-top: 3em;
    border-top: 1px solid #e5e5e5;
    background-color: #f5f5f5;
    font-size: 12px;
    padding: 3rem 1.25rem 3rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 11rem;
  }
</style>
