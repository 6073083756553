import Vue from 'vue'
import Router from 'vue-router'
import { META } from './assets/javascript/meta'

Vue.use(Router)

let routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/Home.vue'),
    meta: META.java
  },
  {
    path: '/embed/',
    name: 'embed',
    component: () => import('./views/EmbedApp'),
    children: [
      {
        path: '/embed/linkcors',
        name: 'embedIText',
        component: () => import('./views/EmbedIde'),
        meta: META.java
      },
      {
        path: '/embed/linkcors-db',
        name: 'embedIText',
        component: () => import('./views/EmbedIde'),
        meta: META.java
      },
      {
        path: '/embed/linkcors-html',
        name: 'embedHTML',
        component: () => import('./views/EmbedHTML'),
        meta: META.java
      },
      {
        path: '/embed/linkcors-multi',
        name: 'embedMulti',
        component: () => import('./views/EmbedMulti'),
        meta: META.java
      },
      {
        path: '/embed/linkcors-multi-db',
        name: 'embedMulti',
        component: () => import('./views/EmbedMulti'),
        meta: META.java
      }
    ]
  }
]

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})
