import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    robotChecked: false,
    lastRobotCheckedTime: null
  },
  mutations: {
    robotCheckDone (state) {
      state.robotChecked = true
      state.lastRobotCheckedTime = Date.now()
    },
    clearRobotCheck (state) {
      state.robotChecked = false
      state.lastRobotCheckedTime = null
    }
  },
  actions: {

  }
})
